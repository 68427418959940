<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      fill-rule="nonzero"
      d="M1.884.318a1.117 1.117 0 0 0-1.56 0 1.072 1.072 0 0 0 0 1.534l3.895 3.83c.432.424 1.13.424 1.562 0l3.896-3.83a1.072 1.072 0 0 0 0-1.534 1.117 1.117 0 0 0-1.561 0L5 3.38 1.884.318z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
